<template>
    <div class="product-page-footer">
        <footer class="product-page-footer1 footerContainer">
            <div class="product-page-separator"></div>
            <div class="product-page-container6">
                <span class="bodySmall product-page-text1">
                    © {{ new Date().getFullYear() }} Akhil Surapuram, All Rights Reserved.
                </span>
                <div class="product-page-icon-group">
                    <a href="mailto:akhil@problempulse.store">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi bi-envelope-at-fill home-icon4 socialIcons" viewBox="0 0 16 16">
                            <path
                                d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                            <path
                                d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'standalone-copyright-footer',
    name: 'footer',
    props: {},
    components: {
    },
    metaInfo: {

    },
}

</script>

<style scoped>

.home-icon4 {
  width: 24px;
  height: 24px;
}
.product-page-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.product-page-container1 {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 100px;
    display: flex;
    align-items: flex-start;
}

.product-page-container2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-page-container3 {
    flex: 0 0 auto;
    width: var(--dl-size-size-maxwidth);
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-page-link {
    display: contents;
}

.product-page-container4 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-twounits);
    border-radius: var(--dl-radius-radius-radius8);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
    text-decoration: none;
    background-color: #ffffff;
}

.product-page-image {
    width: 200px;
    object-fit: cover;
}

.product-page-heading {
    margin-top: var(--dl-space-space-twounits);
    text-transform: capitalize;
}

.product-page-link1 {
    display: contents;
}

.product-page-container5 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    margin-left: var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    background-color: #ffffff;
}

.product-page-image1 {
    width: 200px;
    object-fit: cover;
}

.product-page-text {
    margin-top: var(--dl-space-space-twounits);
    text-transform: capitalize;
}

.product-page-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-page-footer1 {
    width: var(--dl-size-size-maxwidth);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
}

.product-page-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.product-page-container6 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.product-page-icon-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.product-page-icon {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.product-page-icon2 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.product-page-icon4 {
    width: 24px;
    height: 24px;
}

@media(max-width: 1600px) {
    .product-page-container2 {
        height: 903px;
    }

    .product-page-container3 {
        width: var(--dl-size-size-maxwidth);
        margin-bottom: var(--dl-space-space-sixunits);
        justify-content: center;
    }

    .product-page-container4 {
        width: auto;
        align-self: center;
        align-items: center;
        margin-left: var(--dl-space-space-sixunits);
        margin-right: var(--dl-space-space-sixunits);
        flex-direction: column;
        justify-content: center;
    }

    .product-page-image {
        align-self: center;
    }

    .product-page-container5 {
        width: auto;
        align-self: center;
        align-items: center;
        margin-left: var(--dl-space-space-sixunits);
        margin-right: var(--dl-space-space-sixunits);
        flex-direction: column;
        justify-content: center;
    }
}

@media(max-width: 767px) {
    .product-page-footer1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .product-page-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .product-page-container6 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .product-page-text1 {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}

@media(max-width: 479px) {
    .product-page-footer1 {
        padding: var(--dl-space-space-unit);
    }

    .product-page-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .product-page-container6 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .product-page-text1 {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
</style>
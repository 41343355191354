<template>
  <div class="contribution-guide-container">
    <h3 class="contribution-guide-business">Problem Pulse Business</h3>
    <span class="contribution-guide-text">Text</span>
    <h3 class="contribution-guide-entrupuners">Heading</h3>
    <span class="contribution-guide-text1">Text</span>
  </div>
</template>

<script>
export default {
  name: 'ContributionGuide',
  props: {},
  metaInfo: {
    title: 'Contribution-Guide - Problem Pulse',
    meta: [
      {
        property: 'og:title',
        content: 'Contribution-Guide - Problem Pulse',
      },
    ],
  },
}
</script>

<style scoped>
.contribution-guide-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
@media(max-width: 1600px) {
  .contribution-guide-business {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contribution-guide-text {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .contribution-guide-entrupuners {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contribution-guide-text1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>

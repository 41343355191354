<template>
  <div class="tc-container">
    <app-header rootClassName="header-root-class-name2"></app-header>
    <div class="tc-container1"></div>
    <div class="tc-container2"><span class="tc-text">Coming Soon</span></div>
  </div>
</template>

<script>
import AppHeader from '../components/header'

export default {
  name: 'TC',
  props: {},
  components: {
    AppHeader,
  },
  metaInfo: {
    title: 'TC - Problem Pulse',
    meta: [
      {
        property: 'og:title',
        content: 'TC - Problem Pulse',
      },
    ],
  },
}
</script>

<style scoped>
.tc-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tc-container1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.tc-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 1600px) {
  .tc-container2 {
    flex: 1;
    height: 100%;
  }
  .tc-text {
    align-self: center;
  }
}
</style>

<template>
  <div class="contribution-guide-curr-container">
    <app-header rootClassName="header-root-class-name"></app-header>
    <div class="contribution-guide-curr-container1"></div>
    <div class="contribution-guide-curr-container2">
      <span class="contribution-guide-curr-text">Coming Soon</span>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/header'

export default {
  name: 'ContributionGuideCurr',
  props: {},
  components: {
    AppHeader,
  },
  metaInfo: {
    title: 'Contribution-guide-Curr - Problem Pulse',
    meta: [
      {
        property: 'og:title',
        content: 'Contribution-guide-Curr - Problem Pulse',
      },
    ],
  },
}
</script>

<style scoped>
.contribution-guide-curr-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contribution-guide-curr-container1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.contribution-guide-curr-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 1600px) {
  .contribution-guide-curr-container2 {
    flex: 1;
    height: 100%;
  }
  .contribution-guide-curr-text {
    align-self: center;
  }
}
</style>

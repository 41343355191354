<template>
  <div class="product-page-container">
    <app-header rootClassName="header-root-class-name1"></app-header>
    <div class="product-page-container1"></div>
    <div class="product-page-container2">
      <div class="product-page-container3">
        <a href="https://business.problempulse.store" target="_blank" rel="noreferrer noopener" class="product-page-link">
          <div class="product-page-container4">
            <img alt="image" src="/handshake-1000h.png" class="product-page-image" />
            <h3 class="product-page-heading">Business</h3>
          </div>
        </a>
        <a href="https://entrepreneurs.problempulse.store/" target="_blank" rel="noreferrer noopener"
          class="product-page-link1">
          <div class="product-page-container5">
            <img alt="image" src="/entrepreneur-1000h.png" class="product-page-image1" />
            <h3 class="product-page-text">Entrepreneurship</h3>
          </div>
        </a>
      </div>
    </div>
    <standalone-copyright-footer></standalone-copyright-footer>
    
  </div>
</template>

<script>
import AppHeader from '../components/header'
import standaloneCopyrightFooter from '../components/standalone-copyright-footer.vue';

export default {
  name: 'ProductPage',
  props: {},
  components: {
    AppHeader,
    standaloneCopyrightFooter,
  },
  metaInfo: {
    title: 'Product-page - Problem Pulse',
    meta: [
      {
        property: 'og:title',
        content: 'Product-page - Problem Pulse',
      },
    ],
  },
}
</script>

<style scoped>
.product-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.product-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.product-page-container2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-page-container3 {
  flex: 0 0 auto;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-page-link {
  display: contents;
}
.product-page-container4 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
}
.product-page-image {
  width: 200px;
  object-fit: cover;
}
.product-page-heading {
  margin-top: var(--dl-space-space-twounits);
  text-transform: capitalize;
}
.product-page-link1 {
  display: contents;
}
.product-page-container5 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
}
.product-page-image1 {
  width: 200px;
  object-fit: cover;
}
.product-page-text {
  margin-top: var(--dl-space-space-twounits);
  text-transform: capitalize;
}
.product-page-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-page-footer1 {
  width: var(--dl-size-size-maxwidth);
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.product-page-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.product-page-container6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.product-page-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.product-page-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.product-page-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.product-page-icon4 {
  width: 24px;
  height: 24px;
}
@media(max-width: 1600px) {
  .product-page-container2 {
    height: 903px;
  }
  .product-page-container3 {
    width: var(--dl-size-size-maxwidth);
    margin-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .product-page-container4 {
    width: auto;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
    align-items: center;
    margin-left: var(--dl-space-space-sixunits);
    margin-right: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: center;
  }
  .product-page-image {
    align-self: center;
  }
  .product-page-container5 {
    width: auto;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
    align-items: center;
    margin-left: var(--dl-space-space-sixunits);
    margin-right: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .product-page-container3 {
    width: auto;
  }
  .product-page-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .product-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .product-page-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .product-page-text1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .product-page-container3 {
    width: auto;
    flex-direction: column;
  }
  .product-page-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .product-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .product-page-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .product-page-text1 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>

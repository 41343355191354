<template>
  <div class="home-container">
    <app-header></app-header>
    <div class="home-hero">
      <div class="heroContainer home-hero1">
        <div class="home-container01">
          <h1 class="home-hero-heading heading1">
            Fuel Your Growth with Peer Power
          </h1>
          <span class="home-hero-sub-heading bodyLarge">
            <span>
              <span>
                <span>Your go-to platform for expressing pain points and finding business solutions</span>
                <span><span v-html="rawx2mk"></span></span>
              </span>
              <span>
                <span><span v-html="raw5zgp"></span></span>
                <span><span v-html="rawuhud"></span></span>
              </span>
            </span>
            <span>
              <span>
                <span><span v-html="rawxlde"></span></span>
                <span><span v-html="rawlcw9"></span></span>
              </span>
              <span>
                <span><span v-html="rawuh4s"></span></span>
                <span><span v-html="rawv0sa"></span></span>
              </span>
            </span>
          </span>
          <div class="home-btn-group">
            <router-link to="/product-page" class="home-hero-button1 buttonFilled">
              Get Started
            </router-link>
            <a href="#FAQ" class="home-hero-button2 buttonFlat">Learn More →</a>
          </div>
        </div>
      </div>
    </div>
    <div id="features" class="home-features">
      <div class="featuresContainer">
        <div class="home-features1">
          <div class="home-container02">
            <span class="overline">
              <span>features</span>
              <br />
            </span>
            <h2 class="home-features-heading heading2">
              Discover the Power of Our App
            </h2>
            <span class="home-features-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Unlock a world of business problem-solving and entrepreneurial
                    networking
                  </span>
                  <span><span v-html="raw8tr5"></span></span>
                </span>
                <span>
                  <span><span v-html="rawhcxc"></span></span>
                  <span><span v-html="raw7oxb"></span></span>
                </span>
              </span>
              <span>
                <span>
                  <span><span v-html="rawqemk"></span></span>
                  <span><span v-html="raw32xl"></span></span>
                </span>
                <span>
                  <span><span v-html="raw9dd7"></span></span>
                  <span><span v-html="raw6jqv"></span></span>
                </span>
              </span>
            </span>
          </div>
          <div class="home-container03">
            <feature-card heading="Business Problem &amp; Solutions"
              subHeading="Free for All Comunity. Get access to a wide range of business problems and their solutions"></feature-card>
            <feature-card heading="Entrepreneurs' Community"
              subHeading="Connect with like-minded entrepreneurs and share insights and experiences"></feature-card>
            <feature-card heading="Stack Exchange like Functionality"
              subHeading="Ask questions, provide answers, and engage in discussions with the community"></feature-card>
            <feature-card heading="Two Versions Available"
              subHeading="Choose between the version that suits your needs - business problem-focused or entrepreneurs' community-focused"></feature-card>
          </div>
        </div>
      </div>
    </div>
    <div id="pricing" class="home-pricing">
      <div class="pricingContainer">
        <div class="home-container04">
          <span class="overline">
            <span>Pricing</span>
            <br />
          </span>
          <h2 class="heading2">Choose the Right Plan for You</h2>
          <span class="home-pricing-sub-heading bodyLarge">
            <span>
              <span>
                Unlock the full potential of our app with our flexible pricing
                options.
              </span>
            </span>
          </span>
        </div>
        <div class="home-container05">
          <div class="freePricingCard home-pricing-card">
            <div class="home-container06">
              <span class="home-text36 heading3">Basic</span>
              <span class="bodySmall">Suites for everyone, free of charge!</span>
              <div style="margin: 13px;"></div>
            </div>
            <div class="home-container07">
              <span class="home-text37">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-free-plan-price"> 0 </span>
            </div>
            <div class="home-container08">
              <div class="home-container09">
                <span class="home-text40">✔</span>
                <span>Business comunity access</span>
              </div>
              <!-- <div class="home-container09">
                <span class="home-text40">✔</span>
                <span>Share your Problem i.e. Express your pain points</span>
              </div> -->
              <div class="home-container09">
                <span class="home-text40">✔</span>
                <span>Customer pain points</span>
              </div>
              <div class="home-container09">
                <span class="home-text40">✔</span>
                <span>Businesses pain points</span>
              </div>
              <div class="home-container10">
                <span class="home-text41">✔</span>
                <span>Receive Solutions from Community</span>
              </div>
              <div class="home-container11">
                <span class="home-text42">✔</span>
                <span>Check and Compare Existing Solutions </span>
              </div>
              <!-- <div class="home-container12">
                <span class="home-text43">✔</span>
                <span class="bodySmall">Free for All community</span>
              </div> -->
            </div>
            <a href="https://business.problempulse.store/" target="_blank" rel="noreferrer noopener"
              class="home-link buttonOutline">
              Sign up for Free
            </a>
          </div>
          <div class="basicPricingCard home-pricing-card1">
            <div class="home-container13">
              <span class="home-text44 heading3"> Growth Plan </span>
              <span class="bodySmall">
                Pricing Model for Small Businesses and Growing Entrepreneurs
              </span>
              <div style="margin: auto;"></div>
            </div>
            <div class="home-container14">
              <span class="home-text45">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-basic-plan-pricing">
                <span class="home-text50"> $$ </span>
              </span>
              <span class="home-text51">/ month</span>
            </div>
            <div class="home-container15">
              <div class="home-container16">
                <span class="home-text52">✔</span>
                <span class="bodySmall">All features of the lower-tier plan</span>
              </div>
              <div class="home-container17">
                <span class="home-text54">✔</span>
                <span class="bodySmall">Entrepreneurs Community access</span>
              </div>
              <div class="home-container18">
                <span class="home-text55">✔</span>
                <span class="bodySmall">Entrepreneurs Q&A platform</span>
              </div>
              <div class="home-container19">
                <span class="home-text56">✔</span>
                <span class="bodySmall">customer support</span>
              </div>
              <div class="home-container20 hidden">
                <span class="home-text57">✔</span>
                <span class="bodySmall">Custom branding</span>
              </div>
            </div>
            <a href="https://forms.fillout.com/t/kGteQE5Ltrus" target="_blank" rel="noreferrer noopener"
              class="home-link1 buttonFilledSecondary">
              On Request, Contact Us
            </a>
          </div>
          <div class="proPricingCard home-pricing-card2">
            <div class="home-container21">
              <span class="home-text58 heading3">
                <span>Enterprise plan</span>
                <br />
              </span>
              <span class="bodySmall">
                Pricing Model for Big Business and Seasonal Entrepreneurs
              </span>
              <div style="margin: auto;"></div>
            </div>
            <div class="home-container22">
              <span class="home-text61">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-pro-plan-pricing">
                <span>$$$$</span>
              </span>
              <span class="home-text67">/ month</span>
            </div>
            <div class="home-container23">
              <div class="home-container24">
                <span class="home-text68">✔</span>
                <span class="bodySmall"> All features of the lower-tier plan</span>
              </div>
              <div class="home-container25">
                <span class="home-text70">✔</span>
                <span class="bodySmall">Advanced Customer Features</span>
              </div>
              <div class="home-container26">
                <span class="home-text71">✔</span>
                <span class="bodySmall">Priority support</span>
              </div>
              <div class="home-container27 hidden">
                <span class="home-text72">✔</span>
                <span class="bodySmall">Enhanced security</span>
              </div>
              <div class="home-container28 hidden">
                <span class="home-text73">✔</span>
                <span class="bodySmall">Dedicated account manager</span>
              </div>
            </div>
            <a href="https://forms.fillout.com/t/9cHr2BPHqEus" target="_blank" rel="noreferrer noopener"
              class="home-link2 buttonFilledSecondary">
              On Request, Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="home-banner">
      <div class="bannerContainer home-banner1">
        <h1 class="home-banner-heading heading2">Welcome to our App</h1>
        <span class="home-banner-sub-heading bodySmall">
          <span>
            <span>
              <span>
                Welcome to our community! Find and compare solutions for your pain points on our dynamic platform. Just
                like Stack Overflow, anyone can contribute their expertise. Explore alternatives and drive success in your
                endeavors.
              </span>
              <span><span v-html="rawi2q2"></span></span>
            </span>
            <span>
              <span><span v-html="raw0ffu"></span></span>
              <span><span v-html="raw2z1a"></span></span>
            </span>
          </span>
          <span>
            <span>
              <span><span v-html="rawl3dh"></span></span>
              <span><span v-html="rawj7sc"></span></span>
            </span>
            <span>
              <span><span v-html="rawntpw"></span></span>
              <span><span v-html="raw5l8l"></span></span>
            </span>
          </span>
        </span>
        <router-link to="/product-page" class="home-banner-button buttonFilled">
          Try It out
        </router-link>
      </div>
    </div>
    <div id="FAQ" class="home-faq">
      <div class="faqContainer">
        <div class="home-faq1">
          <div class="home-container29">
            <span class="overline">
              <span>FAQ</span>
              <br />
            </span>
            <h2 class="home-text91 heading2">Common questions</h2>
            <span class="home-text92 bodyLarge">
              <span>Here are some of the most common questions that we get.</span>
              <br />
            </span>
          </div>
          <div class="home-container30">
            <app-question1
              answer="It's a free community-maintained Data library of problems i.e pain points of a customer or businesses, along with answers or solutions to the problem."
              question="What is Problem Pulse Business?"></app-question1>
            <app-question1
              answer="Time is precious. Ensure not to solve the same problem already solved. Use the platform to share problems, offer solutions, and promote products/services if they address the problem. This works similar to stack Exchange products."
              question="What is the purpose of Apps?"></app-question1>
            <app-question1
              answer="Any individual running a business or planning to do so, including but not limited to administrative or managerial roles, B-school students and graduates, founders, and future founders."
              question="Who are entrepreneurs?"></app-question1>
            <app-question1
              answer="The business version is open to anyone seeking solutions to business problems, while the Entrepreneurs version is exclusively for entrepreneurs. The Entrepreneurs version is designed for Q&A, networking, knowledge sharing, and advice from other entrepreneurs."
              question="Can I use both versions of the App?"></app-question1>
            <app-question1
              answer="Yes, the business version is free to use, while the Entrepreneur version may involve payment. However, there might be additional premium features or services available for purchase in the future."
              question="Is there a cost associated with using the App?"></app-question1>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  props: {},
  components: {
    AppHeader,
    FeatureCard,
    AppQuestion1,
    AppFooter,
  },
  data() {
    return {
      rawx2mk: ' ',
      raw5zgp: ' ',
      rawuhud: ' ',
      rawxlde: ' ',
      rawlcw9: ' ',
      rawuh4s: ' ',
      rawv0sa: ' ',
      raw8tr5: ' ',
      rawhcxc: ' ',
      raw7oxb: ' ',
      rawqemk: ' ',
      raw32xl: ' ',
      raw9dd7: ' ',
      raw6jqv: ' ',
      rawogif: ' ',
      rawi2q2: ' ',
      raw0ffu: ' ',
      raw2z1a: ' ',
      rawl3dh: ' ',
      rawj7sc: ' ',
      rawntpw: ' ',
      raw5l8l: ' ',
    }
  },
  metaInfo: {
    title: 'Problem Pulse',
    meta: [
      {
        property: 'og:title',
        content: 'Problem Pulse',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}

.home-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-hero-heading {
  max-width: 800px;
  text-align: center;
}

.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}

.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-hero-button1 {
  text-decoration: none;
}

.home-hero-button2 {
  text-decoration: none;
}

.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}

.home-container02 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}

.home-container03 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}

.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-pricing-sub-heading {
  text-align: center;
}

.home-container05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.home-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text36 {
  text-transform: uppercase;
}

.home-container07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text37 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-free-plan-price {
  font-size: 4rem;
  font-weight: 700;
}

.home-container08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text40 {
  line-height: 150%;
}

.home-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text41 {
  line-height: 150%;
}

.home-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text42 {
  line-height: 150%;
}

.home-container12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text43 {
  line-height: 150%;
}

.home-link {
  width: 100%;
  text-decoration: none;
}

.home-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text44 {
  text-transform: uppercase;
}

.home-container14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text45 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-basic-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}

.home-text51 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-container15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.home-container16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text52 {
  line-height: 150%;
}

.home-container17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text54 {
  line-height: 150%;
}

.home-container18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text55 {
  line-height: 150%;
}

.home-container19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text56 {
  line-height: 150%;
}

.home-container20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text57 {
  line-height: 150%;
}

.home-link1 {
  width: 100%;
  text-decoration: none;
}

.home-container21 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text58 {
  text-transform: uppercase;
}

.home-container22 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text61 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-pro-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}

.home-text67 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-container23 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text68 {
  line-height: 150%;
}

.home-container25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text70 {
  line-height: 150%;
}

.home-container26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text71 {
  line-height: 150%;
}

.home-container27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text72 {
  line-height: 150%;
}

.home-container28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-text73 {
  line-height: 150%;
}

.home-link2 {
  width: 100%;
  text-decoration: none;
}

.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-banner-heading {
  text-align: center;
}

.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-banner-button {
  text-decoration: none;
}

.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}

.home-container29 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text91 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text92 {
  text-align: left;
}

.home-container30 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-footer1 {
  width: var(--dl-size-size-maxwidth);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}

.home-container31 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}

.home-nav1 {
  margin-left: 0px;
}

.home-nav2 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-nav3 {
  margin-left: var(--dl-space-space-unit);
}

.home-nav4 {
  margin-left: var(--dl-space-space-unit);
}

.home-nav5 {
  margin-left: var(--dl-space-space-unit);
}

.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}

.home-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}

.home-icon4 {
  width: 24px;
  height: 24px;
}

@media(max-width: 1600px) {
  .home-basic-plan-pricing {
    color: var(--dl-color-gray-black);
    font-size: 3rem;
    font-weight: 700;
  }

  .home-text48 {
    text-decoration: line-through;
  }

  .home-text50 {
    text-decoration: inherit;
  }

  .home-pro-plan-pricing {
    font-size: 3rem;
    font-weight: 700;
  }

  .home-text64 {
    text-decoration: line-through;
  }

  .home-text67 {
    font-size: 1.15rem;
  }
}

@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }

  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }

  .home-hero-heading {
    text-align: center;
  }

  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }

  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }

  .home-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }

  .home-container05 {
    align-items: center;
    flex-direction: column;
  }

  .home-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }

  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
  }

  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
  }

  .home-banner-sub-heading {
    max-width: 100%;
  }

  .home-text92 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}

@media(max-width: 767px) {
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-container03 {
    grid-template-columns: 1fr;
  }

  .home-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-container05 {
    align-items: center;
    flex-direction: column;
  }

  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-faq1 {
    flex-direction: column;
  }

  .home-container29 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .home-text92 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-text95 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}

@media(max-width: 479px) {
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-btn-group {
    flex-direction: column;
  }

  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card2 {
    margin-bottom: 0px;
  }

  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }

  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-text95 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>

<template>
    <div class="home-footer">
        <footer class="home-footer1 footerContainer">
            <div class="home-container31">
                <a href="/" class="logo">Problem pulse</a>
                <nav class="home-nav">
                    <a href="/" class="home-nav1 bodySmall">Home</a>
                    <a href="/product-page" class="home-nav2 bodySmall">Products</a>
                    <a href="/#pricing" class="home-nav3 bodySmall">Pricing </a>
                    <a href="https://business.problempulse.store/privacy" class="home-nav4 bodySmall">Privacy</a>
                    <a href="https://business.problempulse.store/tos" class="home-nav5 bodySmall">Terms (T&C)</a>
                </nav>
            </div>
            <div class="home-separator"></div>
            <div class="home-container32">
                <span class="bodySmall home-text95">
                    © {{ new Date().getFullYear() }} Akhil Surapuram, All Rights Reserved.
                </span>
                <div class="home-icon-group">
                    <a href="mailto:akhil@problempulse.store">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi bi-envelope-at-fill home-icon4 socialIcons" viewBox="0 0 16 16">
                            <path
                                d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                            <path
                                d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>

export default {
    name: 'AppFooter',
    props: {},
    components: {
        
    },
    metaInfo: {

    },

}

</script>

<style scoped>
.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.home-hero {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 96px;
    align-items: flex-start;
    justify-content: center;
}

.home-container01 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-hero-heading {
    max-width: 800px;
    text-align: center;
}

.home-hero-sub-heading {
    font-size: 18px;
    text-align: center;
}

.home-btn-group {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: row;
}

.home-hero-button1 {
    text-decoration: none;
}

.home-hero-button2 {
    text-decoration: none;
}

.home-features {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-features1 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: flex-start;
}

.home-container02 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-features-heading {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-features-sub-heading {
    font-size: 18px;
    text-align: center;
}

.home-container03 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr;
}

.home-pricing {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-container04 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-pricing-sub-heading {
    text-align: center;
}

.home-container05 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.home-container06 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.home-text36 {
    text-transform: uppercase;
}

.home-container07 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
}

.home-text37 {
    font-size: 1.15rem;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 300;
}

.home-free-plan-price {
    font-size: 4rem;
    font-weight: 700;
}

.home-container08 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
}

.home-container09 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text40 {
    line-height: 150%;
}

.home-container10 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text41 {
    line-height: 150%;
}

.home-container11 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text42 {
    line-height: 150%;
}

.home-container12 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text43 {
    line-height: 150%;
}

.home-link {
    width: 100%;
    text-decoration: none;
}

.home-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.home-text44 {
    text-transform: uppercase;
}

.home-container14 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
}

.home-text45 {
    font-size: 1.15rem;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 300;
}

.home-basic-plan-pricing {
    font-size: 4rem;
    font-weight: 700;
}

.home-text51 {
    font-size: 1.15rem;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 300;
}

.home-container15 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
}

.home-container16 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text52 {
    line-height: 150%;
}

.home-container17 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text54 {
    line-height: 150%;
}

.home-container18 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text55 {
    line-height: 150%;
}

.home-container19 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text56 {
    line-height: 150%;
}

.home-container20 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text57 {
    line-height: 150%;
}

.home-link1 {
    width: 100%;
    text-decoration: none;
}

.home-container21 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.home-text58 {
    text-transform: uppercase;
}

.home-container22 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
}

.home-text61 {
    font-size: 1.15rem;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 300;
}

.home-pro-plan-pricing {
    font-size: 4rem;
    font-weight: 700;
}

.home-text67 {
    font-size: 1.15rem;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    font-weight: 300;
}

.home-container23 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
}

.home-container24 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text68 {
    line-height: 150%;
}

.home-container25 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text70 {
    line-height: 150%;
}

.home-container26 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text71 {
    line-height: 150%;
}

.home-container27 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text72 {
    line-height: 150%;
}

.home-container28 {
    gap: 4px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
}

.home-text73 {
    line-height: 150%;
}

.home-link2 {
    width: 100%;
    text-decoration: none;
}

.home-banner {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-banner-heading {
    text-align: center;
}

.home-banner-sub-heading {
    max-width: var(--dl-size-size-maxwidth);
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
}

.home-banner-button {
    text-decoration: none;
}

.home-faq {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-faq1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
}

.home-container29 {
    display: flex;
    max-width: 35%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.home-text91 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text92 {
    text-align: left;
}

.home-container30 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.home-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-footer1 {
    width: var(--dl-size-size-maxwidth);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
}

.home-container31 {
    gap: var(--dl-space-space-unit);
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-nav {
    flex: 0 0 auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: row;
}

.home-nav1 {
    margin-left: 0px;
}

.home-nav2 {
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
}

.home-nav3 {
    margin-left: var(--dl-space-space-unit);
}

.home-nav4 {
    margin-left: var(--dl-space-space-unit);
}

.home-nav5 {
    margin-left: var(--dl-space-space-unit);
}

.home-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.home-container32 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.home-icon-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.home-icon {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.home-icon2 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.home-icon4 {
    width: 24px;
    height: 24px;
}

@media(max-width: 1600px) {
    .home-basic-plan-pricing {
        color: var(--dl-color-gray-black);
        font-size: 3rem;
        font-weight: 700;
    }

    .home-text48 {
        text-decoration: line-through;
    }

    .home-text50 {
        text-decoration: inherit;
    }

    .home-pro-plan-pricing {
        font-size: 3rem;
        font-weight: 700;
    }

    .home-text64 {
        text-decoration: line-through;
    }

    .home-text67 {
        font-size: 1.15rem;
    }
}

@media(max-width: 991px) {
    .home-hero1 {
        flex-direction: column;
    }

    .home-container01 {
        align-items: center;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
        padding-right: 0px;
    }

    .home-hero-heading {
        text-align: center;
    }

    .home-hero-sub-heading {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }

    .home-features-sub-heading {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }

    .home-pricing-sub-heading {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }

    .home-container05 {
        align-items: center;
        flex-direction: column;
    }

    .home-pricing-card {
        width: 100%;
        padding: var(--dl-space-space-twounits);
        max-width: 450px;
    }

    .home-pricing-card1 {
        width: 100%;
        max-width: 450px;
    }

    .home-pricing-card2 {
        width: 100%;
        max-width: 450px;
    }

    .home-banner-sub-heading {
        max-width: 100%;
    }

    .home-text92 {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }
}

@media(max-width: 767px) {
    .home-hero1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-hero-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-features1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-features-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-container03 {
        grid-template-columns: 1fr;
    }

    .home-pricing-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-container05 {
        align-items: center;
        flex-direction: column;
    }

    .home-pricing-card {
        width: 100%;
        max-width: 450px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
    }

    .home-pricing-card1 {
        width: 100%;
        max-width: 450px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
    }

    .home-pricing-card2 {
        width: 100%;
        max-width: 450px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
    }

    .home-banner1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-banner-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-faq1 {
        flex-direction: column;
    }

    .home-container29 {
        max-width: 100%;
        align-self: center;
        align-items: center;
        justify-content: center;
    }

    .home-text92 {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-footer1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .home-container32 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .home-text95 {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}

@media(max-width: 479px) {
    .home-hero1 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }

    .home-container01 {
        margin-bottom: var(--dl-space-space-unit);
    }

    .home-btn-group {
        flex-direction: column;
    }

    .home-features1 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }

    .home-pricing-card2 {
        margin-bottom: 0px;
    }

    .home-banner1 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }

    .home-footer1 {
        padding: var(--dl-space-space-unit);
    }

    .home-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .home-container32 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .home-text95 {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
</style>
<template>
  <div class="header-header" v-bind:class="rootClassName">
    <header data-thq="thq-navbar" class="header-navbar-interactive navbarContainer">
      <a href="/" class="logo">{{ logo }}</a>
      <div data-thq="thq-navbar-nav" class="header-desktop-menu">
        <nav class="header-links">
          <router-link to="/" class="header-nav12 bodySmall">
            {{ nav12 }}
          </router-link>
          <router-link to="/product-page" class="header-nav2 bodySmall">
            {{ nav2 }}
          </router-link>
          <a href="/#pricing" class="header-nav3 bodySmall">{{ nav3 }}</a>
          <a href="https://business.problempulse.store/privacy" class="header-nav4">
            <span class="bodySmall">Privacy</span>
            <br />
          </a>
          <a href="https://business.problempulse.store/tos" class="header-nav5">{{ nav5 }}</a>
        </nav>
        <div class="header-buttons">
          <router-link to="/product-page" class="header-register buttonFilled">
            {{ register }}
          </router-link>
        </div>
      </div>
      <div data-thq="thq-burger-menu" class="header-burger-menu" >
        <svg viewBox="0 0 1024 1024" class="header-icon socialIcons">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z">
          </path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="header-mobile-menu1 mobileMenu">
        <div class="header-nav">
          <div class="header-top">
            <span class="logo">{{ logo1 }}</span>
            <div data-thq="thq-close-menu" class="header-close-menu">
              <svg viewBox="0 0 1024 1024" class="header-icon02 socialIcons">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z">
                </path>
              </svg>
            </div>
          </div>
          <nav class="header-links1">
            <router-link to="/" class="header-nav1 bodySmall">
              {{ nav1 }}
            </router-link>
            <router-link to="/product-page" class="header-nav21 bodySmall">
              {{ nav21 }}
            </router-link>
            <a href="#pricing" class="header-nav31 bodySmall">{{ nav31 }}</a>
            <a href="https://business.problempulse.store/privacy" class="header-nav41 bodySmall">
              {{ nav4 }}
            </a>
            <a href="https://business.problempulse.store/tos" class="header-nav51">{{ nav51 }}</a>
          </nav>
          <div class="header-buttons1">
            <router-link to="/product-page" class="header-register1 buttonFilled">
              {{ register1 }}
            </router-link>
          </div>
        </div>
        <div>
          <svg viewBox="0 0 950.8571428571428 1024" class="header-icon04 socialIcons">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z">
            </path>
          </svg><svg viewBox="0 0 877.7142857142857 1024" class="header-icon06 socialIcons">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z">
            </path>
          </svg><svg viewBox="0 0 602.2582857142856 1024" class="header-icon08 socialIcons">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z">
            </path>
          </svg>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    nav5: {
      type: String,
      default: 'Terms (T&C)\n',
    },
    nav12: {
      type: String,
      default: 'Home',
    },
    nav31: {
      type: String,
      default: 'Pricing',
    },
    nav3: {
      type: String,
      default: 'Pricing',
    },
    logo: {
      type: String,
      default: 'Problem pulse',
    },
    nav2: {
      type: String,
      default: 'Products',
    },
    nav51: {
      type: String,
      default: 'Terms (T&C)\n',
    },
    logo1: {
      type: String,
      default: 'Problem pulse',
    },
    register: {
      type: String,
      default: 'Try Now',
    },
    rootClassName: String,
    nav21: {
      type: String,
      default: 'Products',
    },
    nav4: {
      type: String,
      default: 'Privacy',
    },
    register1: {
      type: String,
      default: 'Get Started\n',
    },
    nav1: {
      type: String,
      default: 'Home',
    },
  },
  data: function () {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toogleBurgerMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}
</script>

<style scoped>
.header-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.header-navbar-interactive {
  width: 100%;
  max-width: var(--dl-size-size-maxwidth);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}

.header-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.header-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.header-nav12 {
  transition: 0.3s;
  text-decoration: none;
}

.header-nav2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.header-nav3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.header-nav4 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.header-nav5 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.header-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-register {
  text-decoration: none;
}

.header-burger-menu {
  display: none;
}

.header-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}

.header-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}

.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.header-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}

.header-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.header-nav1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-nav21 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-nav31 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-nav41 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-nav51 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-register1 {
  text-decoration: none;
}

.header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}



@media(max-width: 1600px) {
  .header-navbar-interactive {
    align-self: center;
    padding-left: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
}

@media(max-width: 1200px) {
  .header-navbar-interactive {
    width: 100%;
  }
}

@media(max-width: 991px) {
  .header-navbar-interactive {
    width: 100%;
  }

  .header-desktop-menu {
    display: none;
  }

  .header-burger-menu {
    display: flex;
  }
}

@media(max-width: 767px) {
  .header-navbar-interactive {
    width: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .header-burger-menu {
    align-items: center;
    justify-content: center;
  }

  .header-nav1 {
    margin-bottom: var(--dl-space-space-unit);
  }

  .header-nav21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .header-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .header-nav41 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .header-nav51 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}

@media(max-width: 479px) {
  .header-navbar-interactive {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }

  .header-mobile-menu1 {
    padding: 16px;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import ProductPage from './views/product-page'
import Home from './views/home'
import TC from './views/tc'
import ContributionGuideCurr from './views/contribution-guide-curr'
import ContributionGuide from './views/contribution-guide'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Product-page',
      path: '/product-page',
      component: ProductPage,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'TC',
      path: '/tc',
      component: TC,
    },
    {
      name: 'Contribution-guide-Curr',
      path: '/contribution-guide-curr',
      component: ContributionGuideCurr,
    },
    {
      name: 'Contribution-Guide',
      path: '/contribution-guide',
      component: ContributionGuide,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
